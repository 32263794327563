import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EmailVerificationBannerProps {
  isDisplayed: boolean;
}

const EmailVerificationBanner = ({ isDisplayed }: EmailVerificationBannerProps) => {
  if (!isDisplayed) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-center bg-yellow-500 px-1 pb-2 pt-1 text-center text-sm text-black sm:text-base">
      <FontAwesomeIcon icon={faUserLock} className="mx-2" />
      <p>
        Váš účet je aktuálně v omezeném režimu. Pro plné využití funkcí ověřte svou e-mailovou
        adresu.
      </p>
    </div>
  );
};

export default EmailVerificationBanner;
