import { faHouse, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import Button, { ButtonColors } from '../elements/Button/Button';

interface ErrorPageProps {
  error: Error;
}

function ErrorPage({ error }: ErrorPageProps) {
  // eslint-disable-next-line no-console
  console.error(error);

  return (
    <div className="flex h-96 flex-col items-center justify-center">
      <img className="mb-8 w-36" src="https://www.grose.cz/grose.png" alt="Groše" />
      <h3 className="mb-8 text-center text-2xl font-bold sm:text-3xl">Došlo k chybě</h3>

      <div className="rounded bg-red-100 p-4 text-red-800">
        <p>{error.message}</p>
      </div>

      <div className="mt-6 flex gap-4">
        <Button
          color={ButtonColors.Blue}
          text="Obnovit"
          icon={faRotateRight}
          showIconMobile
          onClick={() => window.location.reload()}
        />
        <Button
          color={ButtonColors.Blue}
          text="Domů"
          icon={faHouse}
          showIconMobile
          onClick={() => {
            window.location.href = '/';
          }}
        />
      </div>
    </div>
  );
}

export default ErrorPage;
