import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

export enum NavLinkButtonColors {
  Blue = 'blue',
  Red = 'red',
  GradientPurpleBlue = 'gradient-purple-blue',
  GradientPurpleRed = 'gradient-purple-red',
  GradientRedPurpleBlue = 'gradient-red-purple-blue',
}

interface NavLinkButtonProps {
  to: string;
  color: NavLinkButtonColors;
  text: string;
  showIconMobile?: boolean;
  icon?: IconProp;
  className?: string;
}

function NavLinkButton({ to, color, text, showIconMobile, icon, className }: NavLinkButtonProps) {
  const colorClasses: Record<NavLinkButtonColors, string> = {
    [NavLinkButtonColors.Blue]: 'bg-blue-600 hover:bg-blue-700',
    [NavLinkButtonColors.Red]: 'bg-red-500 hover:bg-red-600',
    [NavLinkButtonColors.GradientPurpleBlue]:
      'bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl',
    [NavLinkButtonColors.GradientPurpleRed]:
      'bg-gradient-to-br from-purple-500 to-red-500 hover:bg-gradient-to-bl',
    [NavLinkButtonColors.GradientRedPurpleBlue]:
      'bg-gradient-to-br from-blue-500 via-purple-500 to-red-500 hover:bg-gradient-to-bl',
  };

  const geNavLinkButtonClasses = () =>
    `${className} py-2 px-6 focus:outline-none focus:shadow-outline-red text-white rounded-md transition duration-300 cursor-pointer ${colorClasses[color]}`;

  return (
    <NavLink to={to} className={geNavLinkButtonClasses()}>
      {icon && (
        <FontAwesomeIcon icon={icon} className={`${showIconMobile ? 'mr-2' : 'hidden md:block'}`} />
      )}
      {text}
    </NavLink>
  );
}

export default NavLinkButton;
