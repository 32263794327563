import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, To } from 'react-router-dom';

interface NavigationButtonLinkProps {
  color: 'blue' | 'gradient-purple-blue';
  to: To;
  icon: IconDefinition;
  title: string;
}

const NavigationButtonLink = ({ color, to, icon, title }: NavigationButtonLinkProps) => {
  const getClasses = (isActive: boolean) => {
    if (color === 'gradient-purple-blue') {
      return `${isActive ? 'text-purple-600 sm:text-white sm:bg-gradient-to-br sm:from-purple-600 sm:to-blue-500 sm:hover:bg-gradient-to-bl' : 'text-gray-600 sm:text-white sm:bg-gradient-to-br sm:from-gray-700 sm:to-gray-500 sm:hover:bg-gradient-to-bl'} sm:py-2 sm:px-6 focus:outline-none focus:shadow-outline-red sm:rounded-md transition duration-300 cursor-pointer flex items-center justify-center`;
    }
    return `${isActive ? 'text-blue-600 sm:text-white sm:bg-blue-600 sm:hover:bg-blue-700' : 'text-gray-600 sm:text-white sm:bg-gray-600 sm:hover:bg-gray-700'} sm:py-2 sm:px-6 focus:outline-none focus:shadow-outline-red sm:rounded-md transition duration-300 cursor-pointer flex items-center justify-center`;
  };

  return (
    <NavLink to={to} className={({ isActive }) => getClasses(isActive)}>
      <FontAwesomeIcon icon={icon} className="text-lg sm:text-2xl" />
      <span className="ml-2 hidden sm:inline">{title}</span>
    </NavLink>
  );
};

export default NavigationButtonLink;
