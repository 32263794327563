import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendVerificationEmailApi } from '../api/sendVerificationEmailApi';

export const useSendVerificationEmail = (domain: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => sendVerificationEmailApi(domain),
    onSuccess: (data) => {
      if (data.success) {
        queryClient.invalidateQueries({ queryKey: ['user'] });
      }
    },
  });
};
